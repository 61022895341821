import React, { useState, useEffect } from "react";
import './ranking.css'
import Flippy, { FrontSide, BackSide } from "react-flippy";

function ScoreCounter({ lastUserScore }) {
    const [score, setScore] = useState(lastUserScore);
    const [showRed, setShowRed] = useState(false);
    const [flip, setFlip] = useState(false);

    useEffect(() => {
        if (lastUserScore !== score) {
            setShowRed(true);
            setFlip(!flip)
            setScore(lastUserScore);
            setTimeout(() => {
                setShowRed(false);
                
            }, 1000);
        }
    }, [lastUserScore, score]);

    return (
        <div className="score-counter">
            <Flippy flipDirection='vertical' isFlipped={flip}>
                <FrontSide className={showRed ? "front-side red" : "front-side"}>
                    <span className="score-value">{score}</span>
                </FrontSide>
                <BackSide className={showRed ? "back-side red" : "back-side"}>
                    <span className="score-value">{score}</span>
                </BackSide>
            </Flippy>
        </div>
    );
}

export default ScoreCounter;
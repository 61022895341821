import React, { useEffect, useState } from "react";
import ScoreCounter from "./contador";
import imgLogo from './assets/BLACKAY_NEGRO.png'

// Importa el módulo principal
import { initializeApp } from 'firebase/app';

// Importa el módulo de la base de datos en tiempo real
import { getDatabase, ref, push, set, update, onValue, off } from 'firebase/database';


import './ranking.css'



// rules_version = '2';
// service cloud.firestore {
//   match /databases/{database}/documents {
//     match /{document=**} {
//       allow read, write: if request.auth != null;
//     }
//   }
// }

// Inicializa Firebase con tu configuración
const firebaseApp = initializeApp({
    apiKey: "AIzaSyBRQkEs0l2EAAHzOgPNexAvn5PqLAybCO4",

    authDomain: "gowit-331cc.firebaseapp.com",

    databaseURL: "https://gowit-331cc.firebaseio.com",

    projectId: "gowit-331cc",

    storageBucket: "gowit-331cc.appspot.com",

    messagingSenderId: "760666702253",

    appId: "1:760666702253:web:73952ad5f3683fa267a124",

    measurementId: "G-F5XLWPNCR2"
});

const database = getDatabase(firebaseApp);

function Ranking() {
    const [surfsightToken, setsurfsightToken] = useState('undefined');
    const [users, setUsers] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newUserName, setNewUserName] = useState('');
    const [tasks, setTasks] = useState([]);
    const [lastUser, setLastUser] = useState({ id: null, score: 0, startDate: null, alertasApi: 0 });


    function saveUser(name, score) {
        // Agrega el usuario a la base de datos
        const usersRef = ref(database, 'usersContador');
        const newUserRef = push(usersRef);
        set(newUserRef, {
            name,
            score,
        });
    }

    function updateUserScore(userId, newScore) {
        // Actualiza el puntaje del usuario en la base de datos
        const db = database;
        const userRef = ref(db, `usersContador/${userId}`);
        update(userRef, {
            score: newScore,
        });
    }

    useEffect(() => {
        // Obtiene los usuarios y su puntaje desde la base de datos
        const db = database;
        const usersRef = ref(db, "usersContador");
        const unsubscribe = onValue(usersRef, (snapshot) => {
            const usersList = [];
            snapshot.forEach((childSnapshot) => {
                const user = childSnapshot.val();
                usersList.push({ id: childSnapshot.key, ...user });
            });
            setUsers(usersList);
        });
        // Limpia la referencia cuando el componente se desmonta
        return () => {
            off(usersRef, "value", unsubscribe);
        };
    }, []);


    useEffect(() => {
        const intervalId = setInterval(async () => {
            // fetchVehiculosApi();
            if (lastUser.id !== null) {
                console.log('✅', lastUser.startDate);

                var endDate = getFormattedDate()
                var user = 'cpozas@blackay.com'
                var pass = 'Espacio4680$'

                var requestOptions = {
                    method: 'GET',
                    redirect: 'follow'
                };

                const response = await fetch(`https://api.gowit.us/surfsight/getalertas?username=${user}&password=${pass}&token=${surfsightToken}&startDate=${lastUser.startDate}&endDate=${endDate}`, requestOptions);
                const data = await response.json();
                console.log('✅🌙🚨', data)

                if (data.alertas > lastUser.alertasApi) {
                    handleIncrement(lastUser.id);
                }
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [lastUser]);

    function getFormattedDate() {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = now.getUTCDate().toString().padStart(2, '0');
        const hours = now.getUTCHours().toString().padStart(2, '0');
        const minutes = now.getUTCMinutes().toString().padStart(2, '0');
        const seconds = now.getUTCSeconds().toString().padStart(2, '0');
        const milliseconds = now.getUTCMilliseconds().toString().padStart(3, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    }

    const handleStartCounter = () => {
        if (newUserName !== '') {
            const newUserScore = 0;

            const newUser = {
                id: users.length + 1,
                name: newUserName,
                score: 0,
            };
            setUsers([...users, newUser]);
            saveUser(newUserName, 0);
            setLastUser({ id: newUser.id, score: newUserScore, startDate: getFormattedDate(), alertasApi: 0 });
            setNewUserName('');
            setShowForm(false);
        }
    };


    // function handleIncrement(id) {
    //     const updatedUsers = users.map((user) => {
    //         if (user.id === id) {
    //             return {
    //                 ...user,
    //                 score: user.score + 1,
    //                 name: user.name,
    //             };
    //         } else {
    //             return user;
    //         }
    //     });

    //     setUsers(updatedUsers);
    // }

    function handleIncrement(userId) {
        const updatedUsers = users.map((user) => {
            if (user.id === userId) {
                setLastUser({ id: user.id, score: user.score + 1, startDate: lastUser.startDate, alertasApi: lastUser.alertasApi });
                const updatedUser = { ...user, score: user.score + 1 };
                updateUserScore(userId, updatedUser.score);
                return updatedUser;
            }
            return user;
        });
        setUsers(updatedUsers);
    }

    const handleShowForm = () => {
        setNewUserName('')
        setShowForm(true);
    };

    const handleHideForm = () => {
        setShowForm(false);
    };

    const handleDelete = (id) => {
        const updatedUsers = users.filter((user) => user.id !== id);
        setUsers(updatedUsers);
    };


    return (
        <div className="background">
            <div className="boxLeft">
                <iframe
                    src="https://safe.gowit.us/#/login"
                    width="100%"
                    height="99.6%"
                    title="safe gowit"
                />
            </div>
            <div className="boxRight">
                <img className="logoBlackay" src={imgLogo} alt="BLACKAY" />
                <h2>CONTADOR DE ALERTAS</h2>
                <ScoreCounter lastUserScore={lastUser.score} />
                <h3></h3>
                <button className="add-user-button" onClick={handleShowForm}>Add User</button>
                {showForm && (
                    <div className="add-user-button">
                        <form>
                            <label htmlFor="name">Conductor:</label>
                            <input
                                type="text"
                                id="name"
                                value={newUserName}
                                onChange={(e) => setNewUserName(e.target.value)}
                            />
                            <br />
                            <button type="button" onClick={handleStartCounter}>
                                Add User
                            </button>
                            <button type="button" onClick={handleHideForm}>
                                Cancel
                            </button>
                        </form>
                    </div>
                )}
                <div className="tablaRanking">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Conductor</th>
                                <th>Distracciones</th>
                                {/* <th>Puntaje</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {users
                                .sort((a, b) => a.score - b.score) // Ordenar de menor a mayor
                                .slice(0, 5) // Tomar los 10 primeros
                                .map((user, index) => (
                                    <tr className="rowtr" key={user.id}>
                                        <td style={{ fontWeight: '700' }}>{index + 1}</td>
                                        <td style={{ fontWeight: '400' }}>{user.name}</td>
                                        <td style={{ fontWeight: '700' }}>{user.score}</td>
                                        {/* <td>
                                            <button onClick={() => handleIncrement(user.id)}>+</button>
                                            <button onClick={() => handleDelete(user.id)}>x</button>
                                        </td> */}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default Ranking;
